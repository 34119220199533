import React, { FunctionComponent } from "react"
import Img from "gatsby-image"
import Hero from "~/components/configurable/Hero"
import Heading from "~/components/configurable/Heading"
import Block from "~/components/configurable/Block"

import "./styles.scss"
import Quote from "~/components/configurable/Quote"
import BlockCTA from "~/components/configurable/BlockCTA"

// gatsby-node passes in data as context variable
const BlogItem: FunctionComponent<any> = context => {
  // this grabs all the needed variables from within content.pageContext
  const {
    title,
    body,
  } = context.pageContext

  return (
    <div className="blog-item">
      <Block>
        <div className="container" style={{ maxWidth: 740 }}>
          <Heading className="blog-item__heading" level={3}>
            <span dangerouslySetInnerHTML={{ __html: title }} />
          </Heading>
          <div
            style={{ marginTop: 16 }}
            dangerouslySetInnerHTML={{ __html: body }}
          />
        </div>
        <div className="container" style={{ maxWidth: 740, marginTop:50, display:"flex",  flexDirection: "row", justifyContent: "center"  }}>
            <BlockCTA className="visible-xs" url="mailto:hrn@get-uk.com" external>
              Apply now
            </BlockCTA>
            <BlockCTA className="hidden-xs" url="mailto:hr@get-uk.com" external >
              Apply now
            </BlockCTA>
        </div>
      </Block>
    </div>
  )
}

export default BlogItem
